import Subhub from '../../components/Hubs/Subhub/Subhub'
import QChem_hub from '../../content/qml/hubs/QChem_hub'
import Seo from '../../components/seo'

const { meta_title, meta_description, meta_image, top_section, video_section } =
  QChem_hub

const QuantumChemistryHub = () => {
  return <Subhub top_section={top_section} video_section={video_section} />
}

export default QuantumChemistryHub

export const Head = () => {
  return (
    <Seo title={meta_title} description={meta_description} image={meta_image} />
  )
}
