export default {
  meta_title: 'Quantum Chemistry | PennyLane',
  meta_description:
    'Sit back and learn about quantum chemistry. Explore key concepts, download and run demonstrations, and view our selection of curated videos.',
  meta_image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/qml/quantumChemistry/QChemOpenGraph.png',
  top_section: {
    title: 'Quantum Chemistry',
    sub_title_1:
      'Quantum chemistry is an area of research focused on calculating properties of molecules and the materials built out of them, using quantum mechanics.',
    sub_title_2:
      'Learn why quantum chemistry is one of the leading applications of quantum computing, and discover its fundamental concepts and applications through our collection of articles, tutorials, and demos.',
    link_cards: [
      {
        link: '/qml/what-is-quantum-chemistry',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/qml/QChem.svg',
        alt: '',
        title: 'What is Quantum Chemistry?',
        description:
          'Find out why quantum chemistry is the leading application for quantum computing.',
        linkText: 'Read more',
      },
      {
        link: '/qml/glossary',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/icons/glossary_icon.png',
        alt: '',
        title: 'Key Terms',
        description:
          'Explore different concepts underpinning quantum chemistry and quantum computation.',
        linkText: 'Read more',
      },
      {
        link: '/qml/demonstrations',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/icons/demos_icon.png',
        alt: '',
        title: 'Demos',
        description:
          'Take a dive into quantum chemistry and explore the physics, concepts, and applications through code.',
        linkText: 'Read more',
      },
    ],
  },
  video_section: {
    sub_title:
      'Sit back and explore quantum chemistry with our curated selection of expert videos.',
    img_alt: 'Introduction to Quantum Chemistry thumbnail',
    title: 'Introduction to Quantum Chemistry',
    image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/qml/quantumChemistry/YTIntroToQChem.png',
    videoId: 'khC0CCjxB7k',
  },
}
